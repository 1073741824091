<template>
    <div class="col-md-12">
        <div class="col-md-12 mt-20">
            <div class="col-md-12 row">
                <div class="col-md-9">
                    <h3 class="page-header">
                        <i class="fa fa fa-rupee animated bounceInDown show-info" style="font-size: 24px;"></i>
                        Salary Slip Form
                    </h3>
                </div>
                <!-- <div class="col-md-3"  >
                    <button class="btn btn-success" >+ Add New</button>
                    <button class="btn btn-info ml-10" >Refresh</button>
                </div> -->
            </div>
        </div>
        <div class="col-md-12">
            <div class="panel panel-cascade">
                <div class="panel-body">
                    <div class="form-horizontal cascde-forms">
                        <div class="col-md-12">
                            <div class="form-horizontal cascde-forms">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label class="col-form-label ">Employee Name </label>
                                        <select v-model="form.userid"
                                            class="form-control form-cascade-control input-small">
                                            <option value="">Select</option>
                                            <option v-for="item in employees" :key="item.id" :value="item.id">{{
                                                item.name }}</option>
                                        </select>
                                    </div>
                                    <!-- <div class="col-md-3">
                                        <label class="col-form-label ">Month </label>
                                        <select v-model="form.month"
                                            class="form-control form-cascade-control input-small">
                                            <option value="">Select</option>
                                            <option v-for="val in months" :key="val.value" :value="val.value">
                                                {{ val.month }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="col-form-label ">Year </label>
                                        <select v-model="form.year"
                                            class="form-control form-cascade-control input-small">
                                            <option value="">Select</option>
                                            <option v-for="val in years" :key="val" :value="val">{{ val }}</option>
                                        </select>
                                    </div> -->
                                    <div class="col-md-3">
                                        <label class="col-form-label">Start Date</label>
                                        <input type="date" class="form-control form-cascade-control input-small" v-model="form.startdate" />
                                    </div>
                                    <div class="col-md-3">
                                        <label class="col-form-label">End Date</label>
                                        <input type="date" class="form-control form-cascade-control input-small" v-model="form.enddate" />
                                    </div>
                                    <div class="col-md-3 mt-30">
                                        <button class="btn btn-success" @click="fetchdata()">Go</button>
                                        <button class=" btn btn-danger ml-5" @click="cancel()">Cancel</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-10">
            <div class="panel panel-cascade">
                <div class="panel-body" style="background: #c5dadf;">
                    <div class="col-md-12">
                        <div>
                            <div class="text-center " style="color: #1c304a;">
                                <h4 class="" style="font-size: 34px;font-weight: 700;">V.S. DIGIWORLD</h4>
                                <div>
                                    <p>SHOP NO 16 GANJES MALL HARRIS GANJ KANPUR NAGAR</p>
                                </div>
                                <h5 class="mb-20">Salary Slip</h5>
                                <div class="flex-center-row ">
                                    <div class="col-md-7 text-left">
                                        <div class="flex-row">
                                            <div class="col-md-6 ">
                                                <label>Name Of Employee</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="m-0 ">{{ selecteduser.name ?? '' }}</p>
                                            </div>
                                        </div>
                                        <div class="flex-row mt-10 ">
                                            <div class="col-md-6 ">
                                                <label>Slip Period</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="m-0">{{ this.form.startdate ?? '' }} to {{ this.form.enddate }}</p>
                                            </div>
                                        </div>
                                        <!-- <div class="flex-row mt-10">
                                            <div class="col-md-4 text-left">
                                                <label>Designation</label>
                                            </div>
                                            <div class="col-md-8">
                                                <p class="m-0">Sales Officer</p>
                                            </div>
                                        </div> -->
                                        <div class="flex-row mt-10">
                                            <div class="col-md-6">
                                                <label>Mobiles no.</label>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="m-0" v-if="selecteduser.mobiles != null">{{
                                                    selecteduser?.mobiles[0]?.mobile ?? '' }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-20">
                                    <div class="row justify-content-center">
                                        <div class="col-md-7">
                                            <table class="table text-left" style="background: #c5dadf;">
                                                <tr>
                                                    <td></td>
                                                    <td><strong>BASIC</strong></td>
                                                    <td style="width: 50%;">
                                                        <div
                                                            class="form-control form-cascade-control input-small text-left disabled" >
                                                            {{ form.basic ?? 0 }} / mon</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>Advance</strong></td>
                                                    <td style="width: 50%;">
                                                        <div
                                                            class="form-control form-cascade-control input-small text-left disabled" >
                                                            {{ form.advance ?? 0 }}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>Working Days</strong></td>
                                                    <td style="width: 50%;">
                                                        <div
                                                            class="form-control form-cascade-control input-small text-left disabled" >
                                                            {{ form.workingdays ?? 0 }}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>Pay Scale</strong></td>
                                                    <td style="width: 50%;">
                                                        <div
                                                            class="form-control form-cascade-control input-small text-left disabled" >
                                                            {{ form.payscale ?? 0 }}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>FUEL</strong></td>
                                                    <td style="width: 50%;">
                                                        <div
                                                            class="form-control form-cascade-control input-small text-left disabled">
                                                            {{ form.fuel ?? 0 }}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>ADDITIONAL ALLOWANCE</strong></td>
                                                    <td style="width: 50%;"><input type="number" @input="getTotalOfAmount()"
                                                            class="form-control form-cascade-control input-small"
                                                            v-model="form.addallowance" /></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>TOTAL NO OF SALES</strong></td>
                                                    <td style="width: 50%;">
                                                        <div
                                                            class="form-control form-cascade-control input-small  text-left disabled">
                                                            {{ form.salecount ?? 0 }}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>TOTAL SALES VALUE</strong></td>
                                                    <td style="width: 50%;">
                                                        <div
                                                            class="form-control form-cascade-control input-small  text-left disabled">
                                                            {{ form.totalsale ?? 0 }}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>SALES INCENTIVE</strong></td>
                                                    <td style="width: 50%;"><input type="number"
                                                            @input="getTotalOfAmount()"
                                                            class="form-control form-cascade-control input-small"
                                                            v-model="form.saleincentive" /></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>ADDITIONAL SALES INCENTIVE</strong></td>
                                                    <td style="width: 50%;"><input type="number"
                                                            @input="getTotalOfAmount()"
                                                            class="form-control form-cascade-control input-small"
                                                            v-model="form.addsaleincentive" /></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>TOTAL NO. OF EMI</strong></td>
                                                    <td style="width: 50%;">
                                                        <div
                                                            class="form-control form-cascade-control input-small  text-left disabled">
                                                            {{ form.collectioncount ?? 0 }}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>TOTAL EMI COLLECTION</strong></td>
                                                    <td style="width: 50%;">
                                                        <div
                                                            class="form-control form-cascade-control input-small  text-left disabled">
                                                            {{ this.form.totalcollection ?? 0 }}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>COLLECTION INCENTIVE</strong></td>
                                                    <td style="width: 50%;"><input type="number"
                                                            @input="getTotalOfAmount()"
                                                            class="form-control form-cascade-control input-small"
                                                            v-model="form.collectionincentive" /></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>ADDITIONAL COLLECTION INCENTIVE</strong></td>
                                                    <td style="width: 50%;"><input type="number"
                                                            @input="getTotalOfAmount()"
                                                            class="form-control form-cascade-control input-small"
                                                            v-model="form.addcollectionincentive" /></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>TOTAL DEAL BY</strong></td>
                                                    <td style="width: 50%;">
                                                        <div
                                                            class="form-control form-cascade-control input-small  text-left disabled">
                                                            {{ form.dealbycount ?? 0 }}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>DEAL BY INCENTIVE</strong></td>
                                                    <td style="width: 50%;"><input type="number"
                                                            @input="getTotalOfAmount()"
                                                            class="form-control form-cascade-control input-small"
                                                            v-model="form.dealbyincentive" /></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>ADDITIONAL DEAL BY INCENTIVE</strong></td>
                                                    <td style="width: 50%;"><input type="number"
                                                            @input="getTotalOfAmount()"
                                                            class="form-control form-cascade-control input-small"
                                                            v-model="form.adddealbyincentive" /></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>TOTAL NO OF DELIVERY</strong></td>
                                                    <td style="width: 50%;">
                                                        <div
                                                            class="form-control form-cascade-control input-small  text-left disabled">
                                                            {{ form.deliverycount ?? 0 }}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>DELIVERY INCENTIVE</strong></td>
                                                    <td style="width: 50%;"><input type="number"
                                                            @input="getTotalOfAmount()"
                                                            class="form-control form-cascade-control input-small"
                                                            v-model="form.deliveryincentive" /></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>TOTAL NO OF LEAVE</strong></td>
                                                    <td style="width: 50%;"><input type="number"
                                                        @input="getTotalOfAmount()"
                                                            class="form-control form-cascade-control input-small "
                                                            v-model="form.leavescount" /></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>LEAVE DEDUCTION</strong></td>
                                                    <td style="width: 50%;"><input type="number"
                                                        @input="getTotalOfAmount()"
                                                            class="form-control form-cascade-control input-small"
                                                            v-model="form.leaves" /></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>ADDITIONAL DEDUCTION/PENALITY REMARK</strong></td>
                                                    <td style="width: 50%;"><textarea type="text"
                                                            class="form-control form-cascade-control input-small"
                                                            v-model="form.deductionremark"></textarea></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>ADDITIONAL DEDUCTION/PENALITY</strong></td>
                                                    <td style="width: 50%;"><input type="number"
                                                        @input="getTotalOfAmount()"
                                                            class="form-control form-cascade-control input-small"
                                                            v-model="form.adddeduction" /></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td><strong>Total Amount</strong></td>
                                                    <td style="width: 50%;">
                                                        <div
                                                            class="form-control form-cascade-control input-small text-left disabled">
                                                            {{ form.totalamount ?? 0 }}</div>
                                                    </td>
                                                </tr>


                                            </table>
                                            <div class="text-center">
                                                <button class="btn btn-primary btn-sm" @click="saveForm()">Submit</button>
                                                <button class="btn btn-danger btn-sm"
                                                    @click="cancel()">Cancel</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- v-if="this.selecteduser?.id > 0" -->
        <!-- <div class="col-md-12 mt-10">
            <div class="panel panel-cascade">
                <div class="panel-body" style="background: #c5dadf;">
                    <div class="col-md-12">
                        <div class="row" style="padding: 20px;">
                            <div class="col-md-3 row">
                                <label>VSDIGI-ID : </label>
                                <span>{{ this.selecteduser?.generatedid ?? '' }}</span>
                            </div>
                            <div class="col-md-3 row">
                                <label>Name : </label>
                                <span> {{ this.selecteduser?.name }}</span>
                            </div>
                            <div class="col-md-3 row">
                                <label>Mobile :</label>
                                <span v-if="this.selecteduser.mobiles != null"> {{ this.selecteduser?.mobiles[0]?.mobile
                                    }}</span>
                            </div>
                            <div class="col-md-12 mt-10 row">
                                <label>Address :</label>
                                <div v-if="this.selecteduser.addressess != null">
                                    <span v-if="this.selecteduser?.addressess[0]?.street != null"> {{
                                        this.selecteduser?.addressess[0]?.street }}</span>
                                    <span v-if="this.selecteduser?.addressess[0]?.landmark != null"> {{
                                        this.selecteduser?.addressess[0]?.landmark }}</span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <table class="table">
                            <thead>
                                <th>S.no</th>
                                <th>VSDIGI-ID</th>
                                <th>Account No.</th>
                                <th>Customer Name</th>
                                <th>Sale Value</th>
                                <th>Sale Date</th>

                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in saleData" :key="item.id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ getCustomer(item, 'vsdigi') }}</td>
                                    <td>{{ getAccountNo(item) }}</td>
                                    <td>{{ getCustomer(item, 'name') }}</td>
                                    <td>{{ item.amountwithtax }}</td>
                                    <td>{{ item.transactiondate }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="col-form-label ">No. of Sales </label>
                                <div class="form-control form-cascade-control ">{{ form.noofsale ?? 0 }}
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label class="col-form-label ">Total Sales </label>
                                <div class="form-control form-cascade-control ">{{ form.totalsale ?? 0 }}
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label class="col-form-label ">No. of Collection </label>
                                <div class="form-control form-cascade-control ">{{ form.noofcollection ?? 0
                                    }}
                                </div>

                            </div>
                            <div class="col-md-3">
                                <label class="col-form-label ">Total Collection </label>
                                <div class="form-control form-cascade-control ">{{ form.totalcollection ?? 0
                                    }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 pt-20">
                        <div class="panel panel-cascade">
                            <div class="salary-div">
                                <div class="form-horizontal cascde-forms">
                                    <h5>Basic Salary</h5>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div>
                                                    <label>Basic</label>
                                                </div>
                                                <div>
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <label>Fuel</label>
                                                </div>
                                                <div>
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <label>Additional Incentive</label>
                                                </div>
                                                <div>
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="panel panel-cascade">
                            <div class="panel-body">
                                <div class="form-horizontal cascde-forms">
                                    <div class="col-md-12">
                                        <div class="form-horizontal cascde-forms">

                                            <div class="form-group row">
                                                <div class="col-lg-4 col-md-3">
                                                    <label for="gender" class="col-form-label control-label">Basic
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.basic">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-4 col-md-3">
                                                    <label for="gender" class="col-form-label control-label">Fuel
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.fuel">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-4 col-md-3">
                                                    <label for="gender" class="col-form-label control-label">Additional
                                                        Allowance </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.addallowance">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-4 col-md-3">
                                                    <label for="gender" class="col-form-label control-label">Total Sale
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-4 col-md-3">
                                                    <label for="gender" class="col-form-label control-label">Sale
                                                        Incentive </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.saleincentive">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-4 col-md-3">
                                                    <label for="gender" class="col-form-label control-label">Additional
                                                        Sale Incentive </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.addsaleincentive">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-4 col-md-3">
                                                    <label for="gender" class="col-form-label control-label">Total
                                                        Collection</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalcollection">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-4 col-md-3">
                                                    <label for="gender" class="col-form-label control-label">Collection
                                                        Incentive </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.collectionincentive">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-4 col-md-3">
                                                    <label for="gender" class="col-form-label control-label">Additional
                                                        Collection Incentive </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.addcollectionincentive">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-4 col-md-3">
                                                    <label for="gender" class="col-form-label control-label">Total
                                                        Delivery</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totaldeliverby">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-4 col-md-3">
                                                    <label for="gender" class="col-form-label control-label">Delivery
                                                        Incentive </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.deliveryincentive">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-4 col-md-3">
                                                    <label for="gender" class="col-form-label control-label">Additional
                                                        Delivery Incentive </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.adddeliveryincentive">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-4 col-md-3">
                                                    <label for="gender" class="col-form-label control-label">Total Deal
                                                        by Product </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totaldealby">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-4 col-md-3">
                                                    <label for="gender" class="col-form-label control-label">Deal by
                                                        Incentive </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.dealbyincentive">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-4 col-md-3">
                                                    <label for="gender" class="col-form-label control-label">Additional
                                                        Deal by Incentive </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.adddealbyincentive">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-4 col-md-3">
                                                    <label for="gender" class="col-form-label control-label">Leaves
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.leaves">
                                                </div>
                                            </div>
                                            <div class="form-group text-center">
                                                <button class="btn btn-success" @click="saveForm()">Save</button>
                                                <button class="btn btn-danger ml-5" @click="cancel()">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 pt-20">
                        <div class="panel panel-cascade">
                            <div class="salary-div">
                                <div class="form-horizontal cascde-forms">
                                    <h5>Sale</h5>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div>
                                                    <label>No. of Sale</label>
                                                </div>
                                                <div>
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div>
                                                    <label>Total Sale</label>
                                                </div>
                                                <div>
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div>
                                                    <label>Sale Incentive</label>
                                                </div>
                                                <div>
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div>
                                                    <label>Additional Sale Incentive</label>
                                                </div>
                                                <div>
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 pt-20">
                        <div class="panel panel-cascade">
                            <div class="salary-div">
                                <div class="form-horizontal cascde-forms">
                                    <h5>Collection</h5>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div>
                                                    <label>Total Collection</label>
                                                </div>
                                                <div>
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <label>Collection Incentive</label>
                                                </div>
                                                <div>
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <label>Additional Collection Incentive</label>
                                                </div>
                                                <div>
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 pt-20">
                        <div class="panel panel-cascade">
                            <div class="salary-div">
                                <div class="form-horizontal cascde-forms">
                                    <h5>Delivery</h5>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div>
                                                    <label>No. of Delivery</label>
                                                </div>
                                                <div>
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <label>Delivery Incentive</label>
                                                </div>
                                                <div>
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <label>Additional Delivery Incentive</label>
                                                </div>
                                                <div>
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 pt-20">
                        <div class="panel panel-cascade">
                            <div class="salary-div">
                                <div class="form-horizontal cascde-forms">
                                    <h5>Deal by Product</h5>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div>
                                                    <label>Total Deal by Product</label>
                                                </div>
                                                <div>
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <label>Deal by Incentive</label>
                                                </div>
                                                <div>
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div>
                                                    <label>Additional Deal by Incentive</label>
                                                </div>
                                                <div>
                                                    <input type="number"
                                                        class="form-control form-cascade-control input-small"
                                                        v-model="form.totalsale">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group text-center mt-20">
                        <button class="btn btn-success">Save</button>
                        <button class="btn btn-danger ml-5">Cancel</button>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</template>
<script>
import axios from 'axios'
// import Constants from '../../components/utilities/Constants.vue';
import { mapGetters } from 'vuex'
// import moment  from 'moment'
export default {
    // mixins: [Constants],
    data() {
        return {
            form: new window.Form({
                id: 0,
                advance:0,
                userid: 0,
                basic: 0,
                fuel: 0,
                addallowance: 0,
                totalsale: 0,
                saleincentive: 0,
                addsaleincentive: 0,
                collectionincentive: 0,
                addcollectionincentive: 0,
                totalcollection: 0,
                // totaldealby: 0,
                // totaldeliverby: 0,
                startdate:'',
                enddate:'',
                deliveryincentive: 0,
                adddeliveryincentive: 0,
                dealbyincentive: 0,
                adddealbyincentive: 0,
                month: '',
                year: '',
                storeid: 0,
                leaves: 0,
                leavescount: 0,
                adddeduction: 0,
                deductionremark: '',
                salecount: 0,
                deliverycount: 0,
                dealbycount: 0,
                collectioncount: 0,
                totalamount: 0,
                workingdays: 0,
                payscale:0,

            }),
            dayinmonth:30,
            selecteduser: {},
            selectedmonthyear: [],
            salaryslipdata: [],
            months: [
                { value: '01', month: 'January' },
                { value: '02', month: 'Feburary' },
                { value: '03', month: 'March' },
                { value: '04', month: 'April' },
                { value: '05', month: 'May' },
                { value: '06', month: 'June' },
                { value: '07', month: 'July' },
                { value: '08', month: 'August' },
                { value: '09', month: 'September' },
                { value: '10', month: 'October' },
                { value: '11', month: 'November' },
                { value: '12', month: 'December' },
            ],
            years: ['2022', '2023', '2024'],
            saleData: [],
            customers: [],
            accounts: [],
            

        }
    },
    mounted() {
        this.$store.dispatch('fetchemployees')
        // this.refresh()
        this.initialize()
    },
    computed: {
        ...mapGetters([
            'employees', 'selectedstoreid', 'loggedinuser','edititem'
        ])
    },
    methods: {
        initialize(){
            if(this.edititem!=null){
                this.$store.commit('assignloadingstatus',1)
                console.log("edit item ",this.edititem)
                this.form = new window.Form(this.edititem);
                this.fetchdata()
                // setTimeout(()=>{
                //     this.form.advance = this.edititem.advance
                // },3000)
                this.$store.commit('assignloadingstatus',0)
            }
        },

        saveForm() {
            // this.$store.commit('assignloadingstatus', 1)
            this.form.storeid = this.selectedstoreid
            console.log("save record ",this.form)
            
            if (this.form.userid != ''  && this.form.payscale > 0 && this.form.startdate !='') {
                this.$store.commit('assignloadingstatus',1)
                axios.post('api/salary/slip/save', this.form)
                    .then(response => {
                        if (response.data.status) {
                            this.cancel()
                        }
                    })
                    .catch(error => console.log(error));
            } else {
                alert('Please fill all required fields.')
            }
        },
        getEmployeeName(item) {
            let find = this.employees.find(o => o.id == item.userid)
            return find?.name ?? ''
        },
        // editSlip(item) {
        //     this.form = new window.Form(item);
        // },
        cancel() {
            this.form = new window.Form();
            this.selecteduser = {}
            this.selecteduser.id = 0
            this.selecteduser.month = ''
            this.selecteduser.year = ''
            this.$store.commit('assignloadingstatus', 0)
        },
        // checkSaleNAndCollection() {
        //     console.log(" form ", this.form)
        //     this.form.storeid = this.selectedstoreid
        //     axios.post('api/sale/collection/check', this.form)
        //         .then(response => {
        //             if (response.status == 200) {
        //                 let data = response.data
        //                 this.form.noofsale = data.salecount
        //                 this.form.totalsale = data.totalsale
        //                 this.form.noofcollection = data.collectioncount
        //                 this.form.totalcollection = data.totalcollection
        //             }
        //         })
        //         .catch(error => console.log(error));
        // },
        // disableSlip(item) {
        //     if (confirm('Are you sure you want to disable')) {
        //         let param = { id: item.id }
        //         axios.post('api/salary/slip/disable', param)
        //             .then(response => {
        //                 if (response.data) {
        //                     this.refresh()
        //                 }
        //             })
        //             .catch(error => console.log(error));
        //     }

        // },

        fetchdata() {
            if (this.form.userid == 0) {
                alert('Please select employee')
            } else if (this.form.startdate == '') {
                alert('Please choose start date')
            } else if (this.form.enddate == '') {
                alert('Please choose end date')
            } else {
                this.selecteduser = this.employees.find(o => o.id == this.form.userid)
                // let month = this.months?.find(o => o.value == this.form.month)
                // this.selecteduser.month = month?.month
                this.form.basic = this.selecteduser?.payscale ?? 0
                this.form.fuel = this.selecteduser?.fuel ?? 0
                // console.log("selected ", this.selecteduser)
                let param = { userid: this.form.userid, startdate: this.form.startdate, enddate: this.form.enddate, storeid: this.selectedstoreid }
                axios.post('api/salary/slip/fetch/data', param)
                    .then(response => this.processFetchData(response.data))
                    .catch(error => console.log(error));
            }


        },
        processFetchData(data) {
            console.log(data)
            this.form.advance = data.advance
            this.saleData = data.sale
            this.customers = data.customers
            this.accounts = data.account
            this.form.workingdays = data.workingdays
            this.form.salecount = data.salecount ?? 0
            this.form.totalsale = data.totalsale
            this.form.collectioncount = data.collectioncount
            this.form.deliverycount = data.deliverycount
            this.form.totalcollection = data.totalcollection
            this.form.payscale = parseFloat(Math.round((this.form.basic/this.dayinmonth)*this.form.workingdays)).toFixed(2)
            this.getTotalOfAmount()

        },
        getCustomer(item, type) {
            let find = this.customers?.find(o => o.id == item.customerid)
            if (type == "vsdigi") {
                return find?.generatedid ?? ''
            } else if (type == "name") {
                return find?.name ?? ''
            }
        },
        getAccountNo(item) {
            let find = this.accounts?.find(o => o.saletransactionid == item.id)
            return find?.manualid ?? ''
        },
        getTotalOfAmount() {
            // console.log("basic",this.form.basic)
            // console.log("fuel",parseInt(this.form.fuel))
            // console.log("addallowance",this.form.addallowance)
            // console.log("saleincentive",this.form.saleincentive)
            // console.log("addsaleincentive",this.form.addsaleincentive)
            // console.log("collectionincentive",this.form.collectionincentive)
            // console.log("addcollectionincentive",this.form.addcollectionincentive)
            // console.log("dealbyincentive",this.form.dealbyincentive)
            // console.log("adddealbyincentive",this.form.adddealbyincentive)
            // console.log("deliveryincentive",this.form.deliveryincentive)
            // console.log("leavescount",this.form.leavescount)
            // console.log("leaves",this.form.leaves)
            // console.log("adddeduction",this.form.adddeduction)
            this.form.totalamount = (parseInt(this.form.payscale??0) + parseInt(this.form.fuel??0) + parseInt(this.form.addallowance??0) + parseInt(this.form.saleincentive??0) + parseInt(this.form.addsaleincentive??0) + parseInt(this.form.collectionincentive??0) + parseInt(this.form.addcollectionincentive??0) + parseInt(this.form.dealbyincentive??0) + parseInt(this.form.adddealbyincentive??0) + parseInt(this.form.deliveryincentive??0) - ((parseInt(this.form.leavescount??0) * parseInt(this.form.leaves??0)) + parseInt(this.form.adddeduction??0) + parseInt(this.form.advance??0)))

        }
    },
}
</script>
<style type="text/css">
.table td {
    padding: 6px;
    vertical-align: middle;
    background: transparent;
}
</style>